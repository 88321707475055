import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Calvin English`}</h1>
    <p>{`Template by `}<a parentName="p" {...{
        "href": "https://www.lekoarts.de?utm_source=cara&utm_medium=Starter"
      }}>{`lekoarts.de`}</a>{` - I write about JavaScript and React there!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      